<template>
<div class="footer black">
    <div class="pt-0 p-5 pb-0 container">
        <div class="row text-alig">
            <div class="col-lg-4 col-sm-12">
                <router-link to="/" title="VoiceTelecom">
                    <img src="/images/logo/logo-voicetelecom-aplicacion-tranparente-blanco.png" class="logo mb-3" alt="logo voicetelecom aplicación transparente blanco">
                </router-link>
            </div>
            <div class="col-lg-4 col-sm-12 about-text" >
                <h2 class="footer-heading ">
                    {{$t('layout.footer.title_menu')}}
                </h2>
                <ul class="menu p-3 m-3">
                    <li><router-link class="active" to="/" title="">{{$t('layout.nav.home')}}</router-link></li>
                    <li><router-link to="/nosotros" title="">{{$t('layout.nav.about')}}</router-link></li>
                    <li><router-link to="/servicios" title="">{{$t('layout.nav.services')}}</router-link></li>
                    <li><router-link to="/soporte" title="">{{$t('layout.nav.support')}}</router-link></li>
                    <!-- <li><router-link to="/blog" title="">{{$t('layout.nav.blog')}}</router-link></li> -->
                    <li><router-link to="/contrataciones" title="">{{$t('layout.nav.get')}}</router-link></li>
                </ul>
            </div>
            <div class="col-lg-4 col-sm-12 about-text" >
                <h2 class="footer-heading">
                    {{$t('layout.footer.social_links')}}
                </h2>
                <ul class="social p-3 m-3">
                    <li>
                        <a href="https://www.facebook.com/VoiceTelecomEmpresarial" target="_blank"> <i class="fab fa-facebook"></i> </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/in/voicetelecom/" target="_blank"> <i class="fab fa-linkedin"></i> </a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCIx8psvYEd8NndtYiXxwWng" target="_blank"> <i class="fab fa-youtube"></i> </a>
                    </li>
                    <li>
                        <a href="https://www.pinterest.com.mx/voicetelecomnet/" target="_blank"><i class="fab fa-pinterest-p"></i></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/voicetelecomhogar/" target="_blank"><i class="fab fa-instagram"></i></a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row menu">
            <div class="col-12">
                <p> VoiceTelecom<sup>&copy; </sup>{{year}}  <router-link to="/aviso-privacidad"> {{$t('layout.footer.privacy')}} </router-link> |  {{$t('layout.footer.society')}} <a href="https://miasistente.net/" target="_blank"> MIA<sup>&copy;</sup> Mi Asistente</a>  </p>
            </div>
            
        </div>
    </div>
    <div class="copyr wine p-2">
        <div class="row">
            <div class="col-12 ">
                <p class="footer-last pt-2">{{$t('layout.footer.copyright')}}Powered with <i class="far fa-heart"></i> & <i class="fas fa-mug-hot"></i> by <router-link to="/">VoiceTelecom<sup>&copy;</sup></router-link></p> 
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
        year: "",
        };
    },
    created() {
        let d = new Date();
        this.year = d.getFullYear();
    },
};
</script>

<style scoped>
    .footer{
        font-weight: 500;
    }
    .footer-last{
        font-weight: 100;
        font-size: 15px;
    }
    a{
        text-decoration: none;
        color: #fff
    }
    a:hover{
        color: #363636
    }
    h2{
        color: #fff;
        font-size: 25px;
        font-weight: 500;
        margin-bottom: 10px !important;
    }
    .text-alig{
        text-align: left;
    }
    .logo{
        margin-top: 20px;
		height: 50px;
        text-align: center;
    }
    @media only screen and (max-width: 1200px) {
        .logo {
            height: 50px;
        }
    }
    @media only screen and (max-width: 1199px) {
        .logo {
            height: 40px;
        }
    }
    @media only screen and (max-width: 504px) {
        .menu {
            columns: 2;
        }
    }
    .menu{
        columns: 2;
    }
    .about-text > h2:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 65px;
        height: 4px;
    }
    .about-text > h2:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 71px;
        height: 4px;
        background-color: #fff;
        width: 10px;
    }
    .about-text{
        padding: 0px !important;
    }
</style>