<template>
<div>
<Banner :pageTitle="$route.meta.pageTitle" style="background-image: url(/images/background/fondo-obscuro-hexagonal.png);" />
<section class="page-content">
    <div class="partner-section">
        <SupportIcons/>
    </div>
    <div class="options">
        <div class="container">
            <div class="pt-3 shop-page">
                <div class="row">
                    <div class="col-12">
                        <div class="sec-title">
                            <h2 class="initial"> INTERNET </h2>
                        </div>
                        <div class="shop-tabs">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"> {{$t("pages.support.button.clarification")}} </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{$t("pages.support.button.nointernet")}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " id="lentitud-tab" data-toggle="tab" href="#lentitud" role="tab" aria-controls="lentitud" aria-selected="false"> {{$t("pages.support.button.slowless")}}</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <!-- Saldos y Aclaraciones -->
                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div class="row">
                                        <div class="col-lg-5 ">
                                            <div class="description-text">
                                                <p>{{$t("pages.support.no_internet.general_description")}} </p>
                                                <div class="text-center">
                                                    <a  href="https://wisp.voicetelecom.net/cliente/" target="_blank" > {{$t("pages.support.contact.link_portal")}} </a>
                                                </div>
                                                <p class="mt-3">{{$t("pages.support.contact.principal")}} </p>
                                                <div class="contactus">
                                                    <div class="row ">
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Telefono</span> -->
                                                            <p class="mb-0"><i class="fas fa-phone-alt"></i></p>
                                                            <p class="mb-0 pb-0">Cancún: <a href="tel:+5299850022008">998 500 2200 </a></p>
                                                            <p class="pt-0 mt-0">{{$t("pages.support.contact.contrynumber")}} <a href="tel:+8004830055">800 483 0055</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Email</span> -->
                                                            <p class="mb-0"><i class="fas fa-envelope"></i></p>
                                                            <p><a href="mailto:contacto@voicetelecom.net"> contacto@voicetelecom.net</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <a onclick="gtag_report_conversion_whats('https://wa.me/+529982566845')" target="_blank" class="wa-button">
                                                                <p class="mb-0"><i class="fa fa-whatsapp"></i></p>
                                                            </a>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <img class="jello-horizontal test-soporte" src="../../../public/images/support/estado-de-cuenta-saldos-y-aclaraciones.jpg"  alt="estado de cuenta saldos y aclaraciones">
                                        </div>
                                    </div>
                                </div>
                                <!-- No internet o intermitencia -->
                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="description-text">
                                                <p class="mt-3">{{$t("pages.support.contact.principal")}} </p>
                                                <div class="contactus">
                                                    <div class="row ">
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Telefono</span> -->
                                                            <p class="mb-0"><i class="fas fa-phone-alt"></i></p>
                                                            <p class="mb-0 pb-0">Cancún: <a href="tel:+5299850022008">998 500 2200 </a></p>
                                                            <p class="pt-0 mt-0">{{$t("pages.support.contact.contrynumber")}} <a href="tel:+8004830055">800 483 0055</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Email</span> -->
                                                            <p class="mb-0"><i class="fas fa-envelope"></i></p>
                                                            <p><a href="mailto:contacto@voicetelecom.net"> contacto@voicetelecom.net</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <a onclick="gtag_report_conversion_whats('https://wa.me/+529982566845')" target="_blank" class="wa-button">
                                                                <p class="mb-0"><i class="fa fa-whatsapp"></i></p>
                                                            </a>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <img class="jello-horizontal test-soporte" src="../../../public/images/support/fallas-en-el-servicio-de-internet-intermitencias-lentitud-sin-internet.jpg"  alt="fallas en el servicio de internet intermitencias lentitud sin internet">
                                        </div>
                                    </div>
                                </div>
                                <!-- Lentitud -->
                                <div class="tab-pane fade  " id="lentitud" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="row">
                                        <div class="col-lg-5">
                                            <div class="description-text">
                                                <p>{{$t("pages.support.no_internet.general_description")}}</p>
                                                <div class="text-center">
                                                    <a  href="https://wisp.voicetelecom.net/cliente/" target="_blank" style="text-transform:uppercase"> {{$t("pages.support.contact.link_portal")}} </a>
                                                </div>
                                                <p class="mt-3">{{$t("pages.support.contact.principal")}}</p>
                                                <div class="contactus">
                                                    <div class="row ">
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Telefono</span> -->
                                                            <p class="mb-0"><i class="fas fa-phone-alt"></i></p>
                                                            <p class="mb-0 pb-0">Cancún: <a href="tel:+5299850022008">998 500 2200 </a></p>
                                                            <p class="pt-0 mt-0">{{$t("pages.support.contact.contrynumber")}} <a href="tel:+8004830055">800 483 0055</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <!-- <span>Email</span> -->
                                                            <p class="mb-0"><i class="fas fa-envelope"></i></p>
                                                            <p><a href="mailto:contacto@voicetelecom.net"> contacto@voicetelecom.net</a> </p>
                                                        </div>
                                                        <div class="col-lg-4 col-md-12">
                                                            <a onclick="gtag_report_conversion_whats('https://wa.me/+529982566845')" target="_blank" class="wa-button">
                                                                <p class="mb-0"><i class="fa fa-whatsapp"></i></p>
                                                            </a>
                                                        </div>
                                                        <div class="clearfix"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-7">
                                            <div class="steps">
                                                <p class=""> {{$t("pages.support.no_internet.slowless.description")}} </p>
                                                <ul class="abt-list">
                                                    <li>
                                                        <span>
                                                            <b>{{$t("pages.support.no_internet.slowless.tag1")}}</b> <br>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <b>{{$t("pages.support.no_internet.slowless.tag2")}}</b> <br>
                                                        </span>
                                                    </li>
                                                    <li>
                                                        <span>
                                                            <b>{{$t("pages.support.no_internet.slowless.tag3")}}</b> <br>
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p class="mt-3">{{$t("pages.support.no_internet.slowless.title_video")}} </p>
                                            <div class="video-you">
                                            <iframe src="https://www.youtube.com/embed/r0ObMkoCT04?start=5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                                            </iframe>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="tab-pane fade  " id="lentitud" role="tabpanel" aria-labelledby="profile-tab">
                                    <div class="review-section">
                                        <h3 class="sc-title">3 Reviews</h3>
                                        <ul class="reviews-list">
                                            <li>
                                                <div class="revew">
                                                    <div class="review-thumb">
                                                        <img src="https://via.placeholder.com/70x70" alt="">
                                                    </div>
                                                    <div class="review-details">
                                                        <h3>James Koster  <span>June 7’2018</span></h3>
                                                        <ul class="rating">
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                        </ul>
                                                        <p>Lorem Ipsum is simply dummy text of printing and typesetting industry. Lorem  has been the industry's.</p>
                                                    </div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div class="revew">
                                                            <div class="review-thumb">
                                                                <img src="https://via.placeholder.com/70x70" alt="">
                                                            </div>
                                                            <div class="review-details">
                                                                <h3>Cobus Besten  <span>June 7’2018</span></h3>
                                                                <ul class="rating">
                                                                    <li><i class="fa fa-star"></i></li>
                                                                    <li><i class="fa fa-star"></i></li>
                                                                    <li><i class="fa fa-star"></i></li>
                                                                    <li><i class="fa fa-star"></i></li>
                                                                    <li><i class="fa fa-star"></i></li>
                                                                </ul>
                                                                <p>Lorem Ipsum is simply dummy text of printing and typesetting industry. Lorem  has been the industry's.</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div class="revew">
                                                    <div class="review-thumb">
                                                        <img src="https://via.placeholder.com/70x70" alt="">
                                                    </div>
                                                    <div class="review-details">
                                                        <h3>Magnus <span>June 7’2018</span></h3>
                                                        <ul class="rating">
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                            <li><i class="fa fa-star"></i></li>
                                                        </ul>
                                                        <p>Lorem Ipsum is simply dummy text of printing and typesetting industry. Lorem  has been the industry's.</p>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
</template>

<script>
import Banner from '@/components/layout/Banner.vue' 
import SupportIcons from '@/components/layout/SupportIcons.vue' 
export default {
    components:{
        Banner,
        SupportIcons
    },
    mounted(){
    window.scrollTo(0, 0)
    },
    watch:{
        routeName: function () {
            window.scrollTo(0,4);
        }
    },
    created(){
        window.scrollTo(0, 10);
    },
    methods:{
        scrollBehavior (to, from, savedPosition) {
            if (to.hash) {
                return {
                    selector: to.hash,
                    behavior: 'smooth',
                }
            }
        }
    }
}
</script>

<style scoped>
/* Background */
.page-content {
    padding: 0px 0;
}
.options{
    background-color: #fff;
    margin-bottom: 0px !important;
    padding-bottom: 35px;
    position: relative;
    z-index: 1; 
}
.options::after{
    content: "";
    position: absolute;
    background-image: url("../../../public/images/background/fondo-transparente-hexagonal.png");
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: unset;
    background-size: contain;
    z-index: -1;
}

/* Titulo de la seccion */
.sec-title > h2:before {
    bottom: 20px;
    left: 3% !important;
    width: 76px;
    height: 4px;
}
.sec-title > h2:after {
    bottom: 20px;
    left: 5%;
    height: 4px;
}
.initial{
    padding-top: 20px;
    text-align: initial;
    font-weight: 500;
    font-size: 26px;
}

/* internet opciones */
.description-text p{
    text-align: justify;
}
.description-text a{
    font-weight: 500;
}

.shop-tabs .nav-tabs .nav-link.active{
	box-shadow: 0px 0px 15px 8px rgba(0,0,0,0.28);
}
.nav-link:focus, .nav-link:hover {
    color: #000 !important;
}
/* contacto */
.contactus p{
    text-align: center !important;
}
.contactus a{
    text-decoration: none;
    color:#363636;
    font-weight: 200;
    font-size: 16px;
}
.contactus a:hover{
    color:#009c39b4;
    font-size: 16px;
}
.contactus p{
    color:#363636;
    font-weight: 200;
    font-size: 16px;
}
.contactus i{
    color: #009c39b4;
    font-size: 30px;
    text-align: center;    
}

/* img s-ni-l */
.test-soporte{
    width: 500px;
    border-radius: 20px;
}
.jello-horizontal {
	animation: jello-horizontal 0.9s both;
}
/**
 * ----------------------------------------
 * animation jello-horizontal
 * ----------------------------------------
 */
@keyframes jello-horizontal {
    0% {
        transform: scale3d(1, 1, 1);
    }
    30% {
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        transform: scale3d(0.95, 1.05, 1);
    }
    75% {
        transform: scale3d(1.05, 0.95, 1);
    }
    100% {
        transform: scale3d(1, 1, 1);
    }
}

/* COLUMNA DOS */
.steps{
    text-align: justify;
}
a{
    color: #009c39b4;
    font-size: 22px;
}

/* RESPONSIVE */
@media only screen and (max-width: 767px) {
    .nav-tabs .nav-link{
    padding: 16px 12px;
    }
    .shop-tabs .nav-tabs .nav-link{
        font-size: 11px;
    }
    #player{
    width: 50% !important;
    height: 50% !important;
    }
}
@media only screen and (max-width: 520px){
    .test-soporte{
    width: 300px;
    border-radius: 20px;
}
}
@media only screen and (min-width: 1199px) {
    .video-you{
        max-width:50% !important;
        max-height: 50% !important;
        padding-bottom: 30% !important;
        left: 25%;
    }
}


/* VIDEO STUFF */
.video-you {
    position: relative;
    padding-bottom:56.23%;
    height:0;overflow:hidden;
    max-width:100%;
    background:#000;
    margin:8px 0 8px 0;
}
.video-you iframe {
    position:absolute;
    top: 0;
    left:0;
    width:100%;
    height:100%;
    z-index:100;
    background: transparent;}
.video-you img {
    bottom:0;
    display:block;
    left:0;
    margin:auto;
    max-width:100%;
    width:100%;
    position:absolute;
    right:0;
    top: 0;
    border:none;
    height:auto;
    cursor:pointer;
    -webkit-transition: .4s all;
    -moz-transition: .4s all;
    transition: .4s all;}
.video-you img:hover {
    -webkit-filter:brightness(75%);
    }
.video-you .play {
    height:72px;
    width:72px;
    left:50%;
    top:50%;
    margin-left:-36px;
    margin-top:-36px;
    position:absolute;
    /* background: url("//i.imgur.com/TxzC70f.png") no-repeat; */
    }
.wa-button {
    cursor: pointer;
}
</style>